export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは3月より%d点上がりました。年度始めに良いスタートが切れていますね。<br>
      5月のテーマは、<b>❝５月病を吹き飛ばせ！❞</b>です。生活リズムを整えたり、自分に合ったストレス解消方法を見つけていきましょう！
      5月16日（火）から<b>Walk チーム対抗戦</b>が始まります。仲間と一緒に楽しく続けられる、運動習慣をはじめてみませんか？エントリーは5月15日（月）まで！
      `,
      down: `生活習慣スコアは3月より%d点下がりました。食事・運動など、どの項目に変化がありましたか？<br>
      5月のテーマは、<b>❝５月病を吹き飛ばせ！❞</b>です。生活リズムを整えたり、自分に合ったストレス解消方法を見つけていきましょう！
      5月16日（火）から<b>Walk チーム対抗戦</b>が始まります。仲間と一緒に楽しく続けられる、運動習慣をはじめてみませんか？エントリーは5月15日（月）まで！
      `,
      equal100: `生活習慣スコアは3月に引き続き%満点%です。年度始めの慌ただしい時期に大変素晴らしいです！<br>
      5月のテーマは、<b>❝５月病を吹き飛ばせ！❞</b>です。生活リズムを整えたり、自分に合ったストレス解消方法を見つけていきましょう！
      5月16日（火）から<b>Walk チーム対抗戦</b>が始まります。仲間と一緒に楽しく続けられる、運動習慣をはじめてみませんか？エントリーは5月15日（月）まで！
      `,
      equal: `生活習慣スコアは3月と同じ点数です。<br>
      5月のテーマは、<b>❝５月病を吹き飛ばせ！❞</b>です。生活リズムを整えたり、自分に合ったストレス解消方法を見つけていきましょう！
      5月16日（火）から<b>Walk チーム対抗戦</b>が始まります。仲間と一緒に楽しく続けられる、運動習慣をはじめてみませんか？エントリーは5月15日（月）まで！
      `,
    },
    steps: {
      up: `歩数スコアは3月より%d点上がりました。<br>
      その調子です！<a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">動画</a>を見ながら、「楽」な歩き方を習得して、対抗戦にのぞもう。`,
      down: `歩数スコアは3月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">動画</a>を見ながら、「楽」な歩き方を習得して、対抗戦にのぞもう。`,
      equal: `歩数スコアは3月と同じ点数です。<br>
      <a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">動画</a>を見ながら、「楽」な歩き方を習得して、対抗戦にのぞもう。`,
    },
    exercise: {
      up: `運動のスコアは3月より%d点上がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる<b>「セロトニン」</b>を増やそう。
      `,
      down: `運動のスコアは3月より%d点下がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる<b>「セロトニン」</b>を増やそう。
      `,
      equal: `運動のスコアは3月と同じ点数です。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる<b>「セロトニン」</b>を増やそう。
      `,
    },
    meal: {
      up: `食事のスコアは3月より%d点上がりました。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
      down: `食事のスコアは3月より%d点下がりました。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
      equal: `食事のスコアは3月と同じ点数です。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
    },
    drinking: {
      up: `飲酒のスコアは3月より%d点上がりました。<br>
      お酒を適度に飲むことは、ストレス緩和にもつながります。飲み過ぎないように気を付けてくださいね。<br>
      `,
      down: `飲酒のスコアは3月より%d点下がりました。<br>
      大量の飲酒は、うつ病や認知症の原因になります。<br>
      飲みすぎ注意！
      `,
      equal: `飲酒のスコアは3月と同じ点数です。<br>
      大量の飲酒は、うつ病や認知症の原因になります。<br>
      飲みすぎ注意！
      `,
    },
    sleep: {
      up: `睡眠のスコアは3月より%d点上がりました。<br>
      いい睡眠は、「毎日、起きる時間を揃えること」と「起床時に、日の光をたっぷり浴びること」が重要です。詳細は、<a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">コラム</a>をご覧ください。
      `,
      down: `睡眠のスコアは3月より%d点下がりました。<br>
      いい睡眠は、「毎日、起きる時間を揃えること」と「起床時に、日の光をたっぷり浴びること」が重要です。詳細は、<a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">コラム</a>をご覧ください。
      `,
      equal: `睡眠のスコアは3月と同じ点数です。<br>
      いい睡眠は、「毎日、起きる時間を揃えること」と「起床時に、日の光をたっぷり浴びること」が重要です。詳細は、<a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">コラム</a>をご覧ください。
      `,
    },
    stress: {
      up: `ストレスのスコアは3月より%d点上がりました。<br>
      睡眠をとる、身体を動かす、浴槽に浸かるなど、どれも5月病予防につながります。まずはできそうなものからやってみましょう。
      `,
      down: `ストレスのスコアは3月より%d点下がりました。<br>
      睡眠をとる、身体を動かす、浴槽に浸かるなど、どれも5月病予防につながります。まずはできそうなものからやってみましょう。
      `,
      equal: `ストレスのスコアは3月と同じ点数です。<br>
      睡眠をとる、身体を動かす、浴槽に浸かるなど、どれも5月病予防につながります。まずはできそうなものからやってみましょう。
      `,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up by %d points since March. That's a great start to the fiscal year.<br>
      The theme for May is <b>"Blow those May Blues away! "</b>. So, it's time to adjust your lifestyle rhythms and find ways to relieve stress that suit you!
      The <b>Team Walk Competition</b> is going to kick off on Tuesday, May 16. Why not start an exercise habit that you can enjoy sticking to with your friends? You can enter until Monday, May 15!
      `,
      down: `Your lifestyle score has gone down by %d points since March. Which items have changed in terms of diet, exercise, etc.?<br>
      The theme for May is <b>"Blow those May Blues away! "</b>. So, it's time to adjust your lifestyle rhythms and find ways to relieve stress that suit you!
      The <b>Team Walk Competition</b> is going to kick off on Tuesday, May 16. Why not start an exercise habit that you can enjoy sticking to with your friends? You can enter until Monday, May 15!
      `,
      equal100: `Your lifestyle score is perfect, like it was in March. This is excellent for the hectic beginning of the fiscal year!<br>
      The theme for May is <b>"Blow those May Blues away! "</b>. So, it's time to adjust your lifestyle rhythms and find ways to relieve stress that suit you!
      The <b>Team Walk Competition</b> is going to kick off on Tuesday, May 16. Why not start an exercise habit that you can enjoy sticking to with your friends? You can enter until Monday, May 15!
      `,
      equal: `Your lifestyle score is the same as in March.<br>
      The theme for May is <b>"Blow those May Blues away! "</b>. So, it's time to adjust your lifestyle rhythms and find ways to relieve stress that suit you!
      The <b>Team Walk Competition</b> is going to kick off on Tuesday, May 16. Why not start an exercise habit that you can enjoy sticking to with your friends? You can enter until Monday, May 15!
      `,
    },
    steps: {
      up: `Your steps score has gone up %d pts since March.<br>
      Keep it up! Watch the <a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">videos</a>, learn some easy ways to walk, and get ready for the competition.
      `,
      down: `Your steps score has gone down %d pts since March.<br>
      Watch the <a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">videos</a>, learn some easy ways to walk, and get ready for the competition.
      `,
      equal: `Your steps score is the same as March. <br>
      Watch the <a href="https://go.andwell.jp/library/987e17c5-c9fe-11ed-9a29-0ab1e6a5e015">videos</a>, learn some easy ways to walk, and get ready for the competition.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since March.<br>
      Do cardio like walking or cycling to increase <b>your serotonin</b>, also known as the happiness hormone.
      `,
      down: `Your exercise score has gone down %d pts since March.<br>
      Do cardio like walking or cycling to increase <b>your serotonin</b>, also known as the happiness hormone.
      `,
      equal: `Your exercise score is the same as March.<br>
      Do cardio like walking or cycling to increase <b>your serotonin</b>, also known as the happiness hormone.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since March.<br>
      Breakfast is the key to getting your day started! 
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.
      `,
      down: `Your food score has gone down %d pts since March.<br>
      Breakfast is the key to getting your day started! 
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.
      `,
      equal: `Your food score is the same as March.<br>
      Breakfast is the key to getting your day started! 
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since March.<br>
      Drinking alcohol in moderation can also help relieve stress—be careful not to drink too much!
      `,
      down: `Your alcohol score has gone down %d pts since March.<br>
      Heavy drinking can cause depression and dementia. 
      Be careful not to drink too much!
      `,
      equal: `Your alcohol score is the same as March.<br>
      Heavy drinking can cause depression and dementia. 
      Be careful not to drink too much!
      `,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since March.<br>
      For a good night's sleep, it's important to wake up at the same time every day, and get plenty of sunlight when you do. See the <a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">column</a> for more details.
      `,
      down: `Your sleep score has gone down %d pts since March.<br>
      For a good night's sleep, it's important to wake up at the same time every day, and get plenty of sunlight when you do. See the <a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">column</a> for more details.
      `,
      equal: `Your sleep score is the same as March.<br>
      For a good night's sleep, it's important to wake up at the same time every day, and get plenty of sunlight when you do. See the <a href="https://go.andwell.jp/library/a1a5526c-ca1a-11ed-9a29-0ab1e6a5e015">column</a> for more details.
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since March.<br>
      Things like getting enough sleep and physical exercise and soaking in a bath can all help keep those May Blues at bay. Start with something that seems doable.
      `,
      down: `Your stress score has gone down %d pts since March.<br>
      Things like getting enough sleep and physical exercise and soaking in a bath can all help keep those May Blues at bay. Start with something that seems doable.
      `,
      equal: `Your stress score is the same as March.<br>
      Things like getting enough sleep and physical exercise and soaking in a bath can all help keep those May Blues at bay. Start with something that seems doable.
      `,
    },
  },
}
